<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <agency-left-section
          class="hidden-sm-and-down"
          v-if="profileType == 'Agency'"
        />
        <volunteer-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
        <event-details-section />
        <agency-right-section
          class="hidden-md-and-down"
          v-if="profileType == 'Agency'"
        />
        <volunteer-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyLeftSection from "../../agency/AgencyLeftSection.vue";
import AgencyRightSection from "../../agency/AgencyRightSection.vue";
import VolunteerLeftSection from "../../volunteer/VolunteerLeftSection.vue";
import VolunteerRightSection from "../../volunteer/VolunteerRightSection.vue";
import EventDetailsSection from "./EventDetailsSection.vue";
export default {
  components: {
    AgencyLeftSection,
    VolunteerLeftSection,
    AgencyRightSection,
    VolunteerRightSection,
    EventDetailsSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorCalendars: [],
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
