<template>
  <div class="feed-body">
    <div class="feed-contents">
      <feed-event-card
        :_id="id"
        v-if="id"
        :show-report="true"
        :show-blocked="true"
        :commentId="$route.query.comment_id"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FeedEventCard from "../FeedEventCard.vue";
export default {
  components: { FeedEventCard },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      id: "",
    };
  },
  methods: {
    ...mapActions({
      setEventCommentLoaded: "event/setEventCommentLoaded",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      eventCommentLoaded: "event/eventCommentLoaded",
    }),
  },
  watch: {
    "$route.query._id": function(newValue) {
      if (newValue) {
        this.id = newValue;
      }
    },
    eventCommentLoaded(newValue) {
      if (newValue) {
        this.$vuetify.goTo(document.body.scrollHeight);
        this.setEventCommentLoaded(false);
      }
    },
  },
  mounted() {
    this.id = this.$route.query._id;
    this.setEventCommentLoaded(false);
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.date-picker :deep(.v-picker__title) {
  background: #cde539 !important;
  color: #242f36;
  font-weight: 700;
  font-family: Poppins-Bold;
}
</style>
